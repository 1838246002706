/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { DocumentContext } from "~context/DocumentContext";
import CursorHoverable from "~components/CursorHoverable";
import CursorInverse from "~components/CursorInverse";
import ExhibitionCTA from "~components/ExhibitionCTA";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";
import { splitStringToParagraphs } from "~utils/helpers";

const AboutPage = ({ data, location }) => {
  const { device } = useContext(DocumentContext);

  const { frontmatter } = data.markdownRemark;

  //

  // let logo;
  // let logoType;

  // if (frontmatter?.logo?.childImageSharp?.fluid) {
  //   logo = frontmatter.logo.childImageSharp.fluid;
  //   logoType = `default`;
  // } else if (frontmatter?.logo?.publicURL) {
  //   logo = frontmatter.logo.publicURL;
  //   logoType = `gif`;
  // }

  // {logoType && logoType === `default` && (
  //   <figure className="about-page__logo relative mt-v3">
  //     <Img
  //       className="w-full relative block"
  //       fluid={logo}
  //       alt="With you"
  //     />
  //   </figure>
  // )}

  // {logoType && logoType === `gif` && (
  //   <img
  //     className="about-page__logo relative mt-v3"
  //     src={logo}
  //     alt="With you"
  //   />
  // )}

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="about-page w-full relative bg-purple text-black">
        <CursorInverse>
          <section className="animation-appear w-full relative block pb-v6 xs:pb-v20">
            <div className="grid pt-v14 xs:pt-v15">
              <h1
                className={`grid-end-18 sm:grid-end-24 grid-start-2 sm:grid-start-1 xs:mt-v12 mb-v12 xs:mb-v20 whitespace-pre-wrap ${
                  device === `desktop` ? `f3` : `f4`
                }`}
                dangerouslySetInnerHTML={{ __html: frontmatter.heading }}
              ></h1>

              <div className="grid-end-10 sm:grid-end-24 grid-start-2 sm:grid-start-1 xs:order-1 xs:mt-v16">
                <p
                  className="whitespace-pre-wrap f5"
                  dangerouslySetInnerHTML={{ __html: frontmatter.content }}
                ></p>
              </div>

              <div className="grid-end-10 sm:grid-end-24 grid-start-14 sm:grid-start-1 xs:mt-v2">
                <figure className="w-full relative block">
                  {frontmatter?.image?.childImageSharp?.fluid && (
                    <Img
                      className="w-full h-full object-cover"
                      fluid={frontmatter.image.childImageSharp.fluid}
                    />
                  )}
                </figure>

                <figcaption className="mt-v1 xs:mt-v3 text-right xs:text-left b1 text-white">
                  {frontmatter.imageCaption}
                </figcaption>
              </div>
            </div>
          </section>

          <section className="w-full relative pt-v9 pb-v3 bg-antiflash text-black">
            <article className="grid">
              <h2 className="grid-end-16 sm:grid-end-24 grid-start-2 sm:grid-start-1 xs:mt-v13 xs:mb-v0 mb-v11 f3">
                {frontmatter.purposeHeading}
              </h2>

              <h3 className="grid-end-8 sm:grid-end-24 grid-start-2 sm:grid-start-1 xs:mt-v10 mb-v2 xs:mb-v4 f3">
                {frontmatter.purposeSubheading}
              </h3>

              <ul className="grid-end-14 sm:grid-end-24 sm:grid-start-1 xs:mt-v6 mb-v2 xs:mb-v8 pt-v1 flex flex-wrap justify-between">
                {frontmatter.purposeArticles.map(purposeArticle => (
                  <li
                    key={purposeArticle.heading}
                    className="w-1/2 xs:w-full relative mb-v4 xs:mb-v12 pr-v4"
                  >
                    <h4 className="mb-v1 xs:mb-v6 f5">
                      {purposeArticle.heading}
                    </h4>
                    <p className="b1">{purposeArticle.content}</p>
                  </li>
                ))}
              </ul>
            </article>
          </section>

          <div className="w-full relative bg-black text-white">
            <section className="grid pt-v9 pb-v4">
              <div className="grid-end-22 sm:grid-end-24 grid-start-2 sm:grid-start-1 xs:mt-v8 mb-v2 xs:mb-v1">
                {splitStringToParagraphs(
                  frontmatter.footnote,
                  `mb-v1 xs:mb-v4 f3`
                )}
              </div>
            </section>
          </div>

          <div className="w-full relative bg-black text-white">
            <section className="grid pt-v4 pb-v12 xs:pb-v20">
              <h2 className="grid-end-7 sm:grid-end-24 grid-start-2 sm:grid-start-1 xs:mt-v8 f3">
                {frontmatter.servicesHeading}
              </h2>

              {frontmatter.servicesColumns.map(serviceColumn => {
                return (
                  <div
                    key={serviceColumn.heading}
                    className="grid-end-5 sm:grid-end-12 pt-v1 b1"
                  >
                    <h3
                      className={`mb-v1 xs:mt-v10 xs:mb-v4 ${
                        device === `desktop` ? `f5` : `f5`
                      }`}
                    >
                      {serviceColumn.heading}
                    </h3>

                    <ul>
                      {serviceColumn.services.map(service => (
                        <li
                          key={`${serviceColumn.heading}_${service}`}
                          className="pt-1 xs:pb-v1 b1"
                        >
                          {service}
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </section>
          </div>

          <ExhibitionCTA className="pb-v12" />
        </CursorInverse>
      </Layout>

      <Footer />
    </>
  );
};

export default AboutPage;

export const query = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        content
        image {
          childImageSharp {
            fluid(maxWidth: 1440, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
          publicURL
        }
        imageCaption
        logo {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
          publicURL
        }

        purposeHeading
        purposeSubheading
        purposeArticles {
          heading
          content
        }

        servicesHeading
        servicesColumns {
          heading
          services
        }
        footnote
        seoDescription
        seoKeywords
      }
    }
  }
`;
