/* eslint-disable react/prop-types */

import React from "react";
import PropTypes from "prop-types";

const CursorInverse = ({ children, className }) => {
  return (
    <div role="presentation" className={`${className}`}>
      {children}
    </div>
  );
};

CursorInverse.defaultProps = {
  className: ``
};

CursorInverse.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default CursorInverse;
